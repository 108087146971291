import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./store/index";
import { Home } from './components/App';
import { About } from './components/About';
import { RSVP } from './components/RSVP';
import { Admin } from './components/Admin';
import { Login } from './components/Login';
import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <div>
                {/*Visitor routes*/}
                <Route exact path="/" component={Home} />
                <Route path="/about" component={About} />
                <Route path="/rsvp" component={RSVP} />
                <Route path="/admin" component={Admin} />
                <Route path="/login" component={Login} />
            </div>
        </Router>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
