import { GET_TOKENS, LOG_OUT } from "../constants/action-types";
const initialState = {
  tokens: []
};
function rootReducer(state = initialState, action) {
  if (action.type === GET_TOKENS) {
    return Object.assign({}, state, {
      tokens: state.tokens.concat(action.payload)
    });
  }
  if (action.type === LOG_OUT) {
    state = undefined
  }
  return state;
}
export default rootReducer;
