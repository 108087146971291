import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Wrapper, Descrip, Content, Center, BigSpace, Navbar, GlobalTitle } from './Elements';
import request from 'superagent';
import me from '../img/me.png';
import her from '../img/her.png';

const AnimationContainer = styled.div`
    position:relative;
    width:calc(100% - 2px);
    height:calc(80vh - 100px);
`;

const hemoves = (fitty) => keyframes`
    0% {
        transform: translate(0,0);
    }
    100% {
        transform: translate(${fitty},0);
    }
`;

const shemoves = (fitty) => keyframes`
    0% {
        transform: translate(0,0);
    }
    100% {
        transform: translate(${fitty},0);
    }   
`;

const Andy = styled.img`
    position:absolute;
    right:0;
    bottom:0px;
    width:${props => props.width};
    height:${props => props.height};
    animation-name: ${props => hemoves(props.fitty)};
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-play-state: ${props => props.playmode};
`;

const Laura = styled.img`
    position:absolute;
    left:0;
    bottom:0px;
    width:${props => props.width};
    height:${props => props.height};
    animation-name: ${props => shemoves(props.fitty)};
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-play-state: ${props => props.playmode};
`;

const rotate = keyframes`
    90% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
`;

const topProgress = keyframes`
    3% {
        transform: scale(1) translateY(0);
    }
    90% {
        transform: scale(0) translateY(0);
    }
    100% {
        transform: scale(0) translateY(0);
    }
`;

const lineProgress = keyframes`
    10% { height: 35px; }
    90% { height: 35px; }
    100% { height: 0; }
`;

const bottomProgress = keyframes`
    10% {
        transform: scale(0) translateY(0);
    }
    90% {
        transform: scale(1) translateY(0);
    }
    100% {
        transform: scale(1) translateY(-15px);
    }
`;

const HourGlass = styled.div`
    height: 40px;
    width: 41px;
    animation: ${rotate} 3s cubic-bezier(0.7, 0, 0.2, 1) infinite;
`;

const HGTop = styled.div`
    position: relative;
`;

const HGTopBG = styled.div`
    position: absolute;
    z-index: 0;
    top: -18px;
    width: 100%;
    height: 20px;
    border-top: 1px solid #666666;
    background: #cccccc;
    &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        border-top: 20px solid #cccccc;
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;
    }
`;

const HGTopProgress = styled.div`
    border-top: 20px solid #990000;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    height: 0;
    width: 1px;
    transform: scale(1) translateY(0);
    transform-origin: 50% 100%;
    animation: ${topProgress} 3s linear infinite;
`;

const Line = styled.div`
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 20px;
    height: 0;
    width: 0;
    border-left: 1px solid #990000;
    animation: ${lineProgress} 3s linear infinite;
`;

const HGBottom = styled.div`
    position: relative;
`;

const HGBottomBG = styled.div`
    position: absolute;
    z-index: 0;
    bottom: -1px;
    width: 100%;
    height: 20px;
    border-bottom: 1px solid #666666;
    background: #cccccc;
    &:after {
        content: '';
        position: absolute;
        z-index: 0;
        top: -20px;
        border-bottom: 20px solid #cccccc;
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;
    }
`;

const HGBottomProgress = styled.div`
    border-right: 20px solid transparent;
    border-bottom: 25px solid #990000;
    border-left: 20px solid transparent;
    height: 0;
    width: 1px;
    margin-top: 15px;
    transform: scale(0) translateY(0);
    transform-origin: 50% 100%;
    animation: ${bottomProgress} 3s linear infinite;
`;

const Hearts = styled.div`
    position:absolute;
    width: 50%;
    top:35%;
    left:${props => props.contwidth};
    z-index:99999;
    background-color: #990000;
    color:#ffffff;
    border-radius:5px;
    padding:10px;
    text-align:center;
    display:${props => props.display};
    box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);
    opacity:${props => props.opacity};
    transition: opacity 1s ease;
`;

const Darken = styled.div`
    position:fixed;
    width:100vw;
    height:100vh;
    opacity: 0.75;
    background-color:#999999;
    display:${props => props.display};
`;

const RSVP = styled.div`
    font-family: 'Amatic SC', cursive;
    font-size:1.7em;
    font-weight:bold;
    cursor:${props => props.cursor};
`;

class Loading extends React.Component {
    render() {
        return (
            <LoadingContainer>
                <HourGlass>
                    <HGTop>
                        <HGTopBG />
                        <HGTopProgress />
                    </HGTop>
                    <Line />
                    <HGBottom>
                        <HGBottomBG />
                        <HGBottomProgress />
                    </HGBottom>
                </HourGlass>
            </LoadingContainer>
        );
    }
}
const LoadingContainer = styled.div`
    position:fixed;
    width:41px;
    height:40px;
    top:calc(50% - 20px);
    left:calc(50% - 20px);
`;

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.imageLoaded = this.imageLoaded.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.upandrunning = this.upandrunning.bind(this);
        this.rsvp = this.rsvp.bind(this);
        this.state = {
            imagesLoaded:[],
            upandrunning:false,
            go:"paused",
            afitty:"",
            lfitty:"",
            rsvp:"none",
            opacity:0,
            rsvpmsg:"You must have a token to RSVP",
            goodrsvptoken:false
        };

    }
    componentWillMount() {
        // localStorage.removeItem('rsvp_recorded');
        // check URL for token
        const href = window.location.href;
        let hrefarr = href.split("/");
        if (localStorage.getItem('rsvp_recorded')) {
            this.setState({rsvpmsg:"Thanks, Your RSVP has been recorded. See you in October!"});
        } else if (localStorage.getItem('rsvp_noped')) {
            this.setState({rsvpmsg:"Sorry you can't make it. You will be missed."});
        } else if (hrefarr[3].length > 0) {
            localStorage.setItem('rsvp_token', hrefarr[3].substring(1));
            request
                .get('https://b0985okjv1.execute-api.us-east-1.amazonaws.com/api/rsvp/'+hrefarr[3].substring(1))
                .set('X-API-Key', 'e5Vl3wigYW7H91rFg8Mpr7lCY6F6Cabx8Q5cxloi')
                .then(res => {
                    var obj = JSON.parse(res.body.body)
                    if ('success' in obj) {
                        const fnarr = obj.success.name.split(" ");
                        const firstname = fnarr[0]
                        this.setState({rsvpmsg:"Hi "+firstname+", Click here to RSVP!", goodrsvptoken:true});
                    } else {
                        this.setState({rsvpmsg:"Invalid RSVP Token"});
                    }
                }); 
        } else if (localStorage.getItem('rsvp_token')) {
            // rsvp token already in local storage
            request
                .get('https://b0985okjv1.execute-api.us-east-1.amazonaws.com/api/rsvp/'+localStorage.getItem('rsvp_token'))
                .set('X-API-Key', 'e5Vl3wigYW7H91rFg8Mpr7lCY6F6Cabx8Q5cxloi')
                .then(res => {
                    var obj = JSON.parse(res.body.body)
                    if ('success' in obj) {
                        const fnarr = obj.success.name.split(" ");
                        const firstname = fnarr[0]
                        this.setState({rsvpmsg:"Hi "+firstname+", Click here to RSVP!", goodrsvptoken:true});
                    } else {
                        this.setState({rsvpmsg:"Invalid RSVP Token"});
                    }   
                });
        }
    }
    componentDidMount() {
        const height = document.getElementById('container').clientHeight;
        const width = document.getElementById('container').clientWidth;
        this.setState({cwidth:width, cheight:height});
        window.addEventListener("resize", this.updateDimensions);
    }
    componentDidUpdate() {
        if (this.state.imagesLoaded.length === 2) {
            const cwidth = this.state.cwidth;
            var a = (cwidth/2)-document.getElementById('andy').width;
            var l = (cwidth/2)-document.getElementById('laura').width;
            if (cwidth >= 600) {
                a = a+5;
                l = l+5;
            }   
            if (this.state.go !== "running") {
                this.setState({
                    go:"running",
                    afitty:"-"+a+"px",
                    lfitty:l+"px",
                }, function() {
                    this.upandrunning();
                });
            }
        } 
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    updateDimensions() {
        window.location.reload();
    }
    imageLoaded(image) {
        const list = [image].concat(this.state.imagesLoaded);
        this.setState({imagesLoaded:list});
    }
    upandrunning() {
        if (this.state.upandrunning === false) {
            setTimeout(() => {
                this.setState({upandrunning:true, rsvp:"block"}, function() {
                    console.log("holding hands:", this.state.upandrunning);
                });
                setTimeout(() =>
                    this.setState({ opacity: 1 }), 10
                );
            }, 4000);
        }
    }
    rsvp() {
        this.props.history.push('/rsvp');
    }
    render() {
        if(this.state === null || !this.state.cwidth) {
            return (
                <Wrapper color="white">
                    <Navbar />
                    <Content><BigSpace />
                    <Center>
                        <GlobalTitle title="Won't you join us?" large inline /><Descrip>we got hitched!</Descrip>
                    </Center>
                    </Content>
                    <AnimationContainer id="container" />
                </Wrapper>
            );
        } else {
            const cwidth = this.state.cwidth;
            const cheight = this.state.cheight;
            var hisheight, hiswidth, herheight, herwidth;
            if (cwidth > 850 && cheight > 460) {
                hisheight = cheight+"px";
                hiswidth = "auto";
                herheight = cheight - 30+"px";
                herwidth = "auto";
            } else if (cwidth < 520) {
                hisheight = cheight/2.5+"px";
                hiswidth = "auto";
                herheight = cheight/2.5 - 10+"px";
                herwidth = "auto";
            } else {
                hisheight = cheight/1.5+"px";
                hiswidth = "auto";
                herheight = cheight/1.5 - 10+"px";
                herwidth = "auto";
            }
            return (
                <Wrapper color="white">
                    <Darken display={this.state.rsvp} />
                    <Navbar />
                    <Content><BigSpace />
                    <Center>
                        <GlobalTitle title="Won't you join us?" large inline /><Descrip>we got hitched!</Descrip>
                    </Center>
                    </Content>
                    <AnimationContainer id="container">
                        <Andy id="andy" fitty={this.state.afitty} playmode={this.state.go} src={me} width={hiswidth} height={hisheight} onLoad={() => this.imageLoaded("andy") } />
                        <Laura id="laura" fitty={this.state.lfitty} playmode={this.state.go} src={her} width={herwidth} height={herheight} onLoad={() => this.imageLoaded("laura") } />
                        <Hearts opacity={this.state.opacity} contwidth={"calc(50% - "+(cwidth/2)/2+"px)"} display={this.state.rsvp}>
                {!this.state.goodrsvptoken ? (
                            <RSVP cursor="default">{this.state.rsvpmsg}</RSVP>
                ) : (
                            <RSVP cursor="pointer" onClick={this.rsvp}>{this.state.rsvpmsg}</RSVP>
                )}
                        </Hearts>
                    </AnimationContainer>
                {this.state.imagesLoaded.length < 2 &&
                    <Loading />
                }
                </Wrapper>
            );
        }
    }
}

export {Home}
