import React from 'react';
import styled from 'styled-components';
import request from 'superagent';
import { Wrapper, Bold, Spacer, Content, Center, BigSpace, Navbar, GlobalTitle } from './Elements';
import { connect } from "react-redux";
import uuidv1 from "uuid";
import { login } from "../actions/index";
function mapDispatchToProps(dispatch) {
  return {
    login: tokens => dispatch(login(tokens))
  };  
}
const mapStateToProps = state => {
  return { tokens: state.tokens };
};
const LoginInput = styled.input`
    padding:8px;
    margin-left:5px;
    margin-bottom:5px;
    border:2px solid #cfcfcf;
    border-radius: 3px;
    width:50%;
    &:hover {
        border:2px solid #999999;
    }   
    &:focus {
        outline:none !important;
        background-color:#f5f8fc;
    }   
    @media (max-width: 414px) {
        width:90%;
    }   
`;

const SubmitInput = styled.input`
    margin-top: 5px;
    visibility: ${props => props.pano ? 'hidden' : 'visible'};
    border-radius: 4px;
    background-color:#cfcfcf;
    border:2px solid #cfcfcf;
    color:white;
    font-size:1em;
    padding:5px;
    text-align:center;
    cursor:pointer;
    margin-left: ${props => props.account ? '5px' : '0px;'};
    padding-top: ${props => props.account ? '3px' : '5px;'};
    &:hover {
        background: #999999;
        border:2px solid #999999;
    }
    &:focus {
            outline:none !important;
    }
`;

const LoginContainer = styled.div`
    display:inline-block;
    width:60vw;
`;

class ConnectedLogin extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.forcePassChange = this.forcePassChange.bind(this);
        this.state = {
            'fpc':false,
            'oldpass':'',
            'olduser':'',
            'authenticated':false,
        };
    }
    componentWillMount() {
        const cachedtokens = localStorage.getItem("auth_tokens");
        if (cachedtokens) {
            // tokens exist in cahce!
            var currentDate = new Date();
            var onehour = 60 * 60 * 1000;
            var ctn = currentDate.getTime();
            var ttn = new Date(localStorage.getItem("token_date"));
            ttn = ttn.getTime();
            if (ctn - ttn < onehour) {
                console.log("tokens exist in cache are not expired.");
                window.location.href = "/admin";
            }
        }
    }
    componentDidUpdate() {
        if (this.props.tokens[0].success && this.props.tokens[0].success === "authenticated") {
            if (this.state.authenticated === false) {
                this.setState({'authenticated':true});
                window.location.reload();
            }
        } else if (this.props.tokens[0].success && this.props.tokens[0].success === "force password change" && this.state.fpc !== true) {
            this.setState({
                'fpc':true, 
                'oldpass':this.PassInput.value,
                'olduser':this.UserInput.value
            });
        }
    }
    handleSubmit(event) {
        event.preventDefault();
        var data = this;
        if (data.UserInput.value.length < 1) {
            alert("Please Enter a Name");
            document.forms["login"]["username"].focus();
        } else if (data.PassInput.value.length < 1){
            alert("Please Enter a Password");
            document.forms["login"]["password"].focus();
        } else {
            // do something here
            const id = uuidv1();
            const userpass = {'username':data.UserInput.value, 'password':data.PassInput.value};
            this.props.login({ userpass, id });
        }
    }
    forcePassChange(event) {
        event.preventDefault();
        var data = this;
        var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (data.PassInput.value.length < 1) {
            alert("Please Enter a Password");
            document.forms["fpc"]["password"].focus();
        } else if (data.PassAgainInput.value.length < 1) {
            alert("Please Enter a Second Password");
            document.forms["fpc"]["passwordagain"].focus();
        } else if (data.PassInput.value !== data.PassAgainInput.value) {
            alert("Passwords Don't Match");
            document.forms["fpc"]["passwordagain"].focus();
        } else if (!regex.test(data.PassInput.value)) {
            alert("Passwords must be 8 characters, have uppercase letters, lowercase letters, special characters, and numbers. we don't wanna get hacked. sorry.");
            document.forms["fpc"]["password"].focus();
        } else {
            // do something here
            console.log("change passwords", data.state.oldpass, data.state.olduser);
            request
                .post(process.env.REACT_APP_MASTER_OF_CEREMONY+'changepass')
                .send({ key: 'gTpHoSc2SW7FzaqFPNb0ta93F92MGxuv2x8Ii2Rt', username: data.state.olduser, oldpassword: data.state.oldpass, newpassword: data.PassInput.value })
                .then(res => {
                    window.location.reload();
                });
        }
    }
    render() {
        return(
            <Wrapper color="white">
                <Navbar />
                <Content><BigSpace />
                <Center>
                    <GlobalTitle title="Login Below" large />
                    <LoginContainer>
            {!this.state.fpc ? (
                        <form name="login" onSubmit={this.handleSubmit}>
                            <Bold>Username or Email</Bold><br />    
                            <LoginInput 
                                type="text"
                                name="username"
                                ref={(input) => this.UserInput = input}
                            /><Spacer />

                            <Bold>Password</Bold><br />
                            <LoginInput 
                                type="password"
                                name="password"
                                ref={(input) => this.PassInput = input}
                            /><Spacer />

                            <SubmitInput type="submit" value="Login" />
                        </form>
            ) : (
                        <form name="fpc" onSubmit={this.forcePassChange}>
                            As this is your first time to login, you'll need to pick a new password. Once your new password is set you will be asked to log in again.<Spacer />

                            <Bold>Password</Bold><br />
                            <LoginInput 
                                type="password"
                                name="password"
                                ref={(input) => this.PassInput = input}
                            /><Spacer />

                            <Bold>Password Again</Bold><br />
                            <LoginInput 
                                type="password"
                                name="passwordagain"
                                ref={(input) => this.PassAgainInput = input}
                            /><Spacer />

                            <SubmitInput type="submit" value="Change Password" />
                        </form>
            )}
                    </LoginContainer>
                </Center>
                </Content>
            </Wrapper>
        );
    }
}

const Login = connect(mapStateToProps, mapDispatchToProps)(ConnectedLogin);
export {Login}
