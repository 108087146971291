import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import request from 'superagent';
import { Wrapper, Spacer, Content, Bold, Center, BigSpace, Navbar, GlobalTitle } from './Elements';

const Instructions = styled.div`
    font-style:italic;
    display:inline-block;
    margin-top:10px;
`;

const Who = styled.div`
    margin-top:10px;
    display:inline-block;
    font-size:1.2em;
`;

const GuestInput = styled.input`
    display:inline-block;
    padding:8px;
    border:2px solid #cfcfcf;
    border-radius: 3px;
    width:10px;
    &:hover {
        border:2px solid #999999;
    }   
    &:focus {
        outline:none !important;
        background-color:#f5f8fc;
    }   
`;

const SubmitInput = styled.input`
    display:inline-block;
    margin-top: 5px;
    visibility: ${props => props.pano ? 'hidden' : 'visible'};
    border-radius: 4px;
    background-color:#d1432b;
    border:2px solid #d1432b;
    color:white;
    font-size:1em;
    padding:5px;
    text-align:center;
    cursor:pointer;
    box-shadow: 0 2px 2px 0px rgba(153, 153, 153, .8);
    -moz-box-shadow: 0 2px 2px 0px rgba(153, 153, 153, .8);
    -webkit-box-shadow: 0 2px 2px 0px rgba(153, 153, 153, .8);
    margin-left: ${props => props.account ? '5px' : '0px;'};
    padding-top: ${props => props.account ? '3px' : '5px;'};
    &:hover {
        background: #990000;
        border:2px solid #990000;
    }   
    &:focus {
            outline:none !important;
    }   
`;

const NopeButton = styled.button`
    background: white;
    color: #999999;
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: 2px solid #999999;
    cursor:pointer;
    border-radius: 3px;
    &:hover {
        background: #999999;
        color: white;
    }   
    &:focus {
        outline:none !important;
    }   
`;

const AboutLink = styled(Link)`
    color:#33672b;
    text-decoration:underline;
    &:hover {
        text-decoration:none;
        color:#003c00;
    } 
`;

class RSVP extends React.Component {
    state = { 
        'guests': 0,
        'name': '',
        'email': '',
    };
    handleSubmit = this.handleSubmit.bind(this);
    nope = this.nope.bind(this);
    componentWillMount() {
        if (localStorage.getItem('rsvp_token')) {
            // rsvp token in local storage
            request
                .get('https://b0985okjv1.execute-api.us-east-1.amazonaws.com/api/rsvp/'+localStorage.getItem('rsvp_token'))
                .set('X-API-Key', 'e5Vl3wigYW7H91rFg8Mpr7lCY6F6Cabx8Q5cxloi')
                .then(res => {
                    var obj = JSON.parse(res.body.body)
                    if ('success' in obj) {
                        const name =  obj.success.name;
                        const email =  obj.success.email;
                        this.setState({'name':name,'email':email});
                    } else {
                        this.props.history.push('/');
                    }   
                }); 
        } else {
            this.props.history.push('/');
        }
    }
    handleSubmit(event) {
        event.preventDefault();
        var data = this;
        if (data.UserInput.value.length < 1) {
            alert("Please Enter a Number of guests. If it's just you, type \"0\"");
            document.forms["rsvp"]["guests"].focus();
        } else if (data.UserInput.value > 3) {
            alert("Please enter a number below 4. If you require more guests (for children? .. talking to you Nate), please contact us directly.");
            document.forms["rsvp"]["guests"].focus();
        } else {
            const dbguests = parseInt(data.UserInput.value) + 1;
            const dbname = data.state.name.replace(" ", "%20");
            request
                .post(process.env.REACT_APP_MASTER_OF_CEREMONY+'rsvp')
                .send({ key: "0WJMTR6oMm6MyrIEHts901M4e7shtkrF48w0uqvE", num: dbguests, invitename: dbname, email: this.state.email })
                .then(res => {
                    localStorage.setItem('rsvp_recorded', 'yes');
                    this.props.history.push('/');
                });
        }
    }
    nope() {
        localStorage.setItem('rsvp_noped', 'yes');
        this.props.history.push('/');
    }
    onChange(e) {
         this.setState({guests: e.target.value})
    }
    render() {
        if (!this.state.name) {
            return(
                <Wrapper color="white">
                    <Navbar />
                    <Content><BigSpace />
                    <Center>
                        <GlobalTitle title="Please wait while we fetch your data..." large />
                    </Center>
                    </Content>
                </Wrapper>
            );
        } else {
            return(
                <Wrapper color="white">
                    <Navbar />
                    <Content><BigSpace />
                    <Center>
                        <GlobalTitle title="Won't you join us?" large />
                        <GlobalTitle title="October 25-27, 2019 - El Paso, TX" green />
                        <AboutLink to="/about">More Information Here</AboutLink><Spacer />

                        <Instructions>Please update the number of guests and then click "RSVP" below:</Instructions><Spacer />
                        <form name="rsvp" onSubmit={this.handleSubmit}>
                            <Who><Bold>{this.state.name}</Bold> will be attending with <GuestInput 
                                type="text"
                                name="guests"
                                ref={(input) => this.UserInput = input}
                                value={this.state.guests}
                                onChange={(value) => this.onChange(value)}
                            /> guests.</Who><Spacer />
                            <SubmitInput  type="submit" value="Répondez S'il Vous Plaît" />
                        </form>
                        <NopeButton onClick={ () => this.nope() }>Sorry, Can't Make It</NopeButton> 
                    </Center>
                    </Content>
                </Wrapper>
            );
        }
    }
}

export {RSVP}
