import React from 'react'; 
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Title = styled.h1`
    font-family: 'Amatic SC', cursive;
    font-size: ${props => props.large ? '2.4em;' : props.medium ? '2em' : '1.5em'};
    color: ${props => props.green ? '#003c00' : '#990000'};
    display: ${props => props.inline ? 'inline-block' : 'block'};
    margin-right: ${props => props.inline ? '5px' : '0px'};
    margin-top: 8px;
    @media (max-width: 600px) {
        font-size: ${props => props.large ? '1.9em;' : props.medium ? '1.7em' : '1.5em'};
    }
    @media (max-width: 330px) {
        font-size: ${props => props.large ? '1.4em;' : props.medium ? '1.4em' : '1.4em'};
    }
`;

// maroon #990000
// light #d1432b
// dark #650000

// green #003c00
// light #33672b 
// dark #001900

const Descrip = styled.div`
    display:inline-block;
    color:#666666;
    @media (max-width: 560px) {
        display:block;
        margin-top:-24px;
    }
    @media (max-width:330px) {
        margin-top:-18px;
    }
`;

const Wrapper = styled.div`
    position:relative;
    width:100vw;
    height:100wh;
    padding-bottom:40px;
    background-color: ${props => props.color};
`;

const Content = styled.div`
    margin: auto;
    max-width: 1000px;
    font-size: 1em;
    clear:both;
    @media (max-width: 1011px) {
        padding-left:6px;
        padding-right:6px;
    }
`;

const Nav = styled.nav`
    background-color: #cfcfcf;
    box-shadow: 0 2px 2px 0px rgba(153, 153, 153, .8);
    -moz-box-shadow: 0 2px 2px 0px rgba(153, 153, 153, .8);
    -webkit-box-shadow: 0 2px 2px 0px rgba(153, 153, 153, .8);
    min-height: 60px;
    padding-top:5px;
    padding-bottom:5px;
    width:100vw;
    color: white;
    position:fixed;
    top:0;
    text-align: center;
    z-index:99;
`;

const LogoHolder = styled.div`
    cursor:pointer;
    display:inline-block;
    margin-top:14px;
`;

const Logo = styled.div`
    display:inline-block;
    padding:7px;
    background-color:#990000;
    border-radius:5px;
    color:#ffffff;
`;

const Center = styled.div`
    text-align: center;
    width:100%;
`;

const BigSpace = styled.div`
    height: ${props => props.loaded ? '185px' : '75px'};
    @media (max-width: 600px) {
        height: ${props => props.loaded ? '170px' : '75px'};
    }
    @media (max-width: 560px) {
        height: ${props => props.loaded ? '195px' : '75px'};
    }
    @media (max-width: 330px) {
        height: ${props => props.loaded ? '184px' : '75px'};
    }
`;

const Spacer = styled.div`
    margin-bottom:10px;
`;

const Bold = styled.span`
    font-weight:bold;
`;

class GlobalTitle extends React.Component {
    render(title) {
        var medium = {};
        var large = {};
        var inline = {};
        var green = {};
        if (this.props.large !== undefined) {
            large['large'] = "large";
        }
        if (this.props.medium !== undefined) {
            medium['medium'] = "medium";
        }
        if (this.props.inline !== undefined) {
            inline['inline'] = "inline";
        }
        if (this.props.green !== undefined) {
            green['green'] = "green";
        }
        return (
            <Title {...large} {...medium} {...inline} {...green}>{this.props.title}</Title>
        )
    }
}

class Navbar extends React.Component {
    render() {
        if (window.location.pathname === "/about") {
            return (
                <Nav>
                    <Link to="/">
                    <LogoHolder>
                        <Logo><Center>Go Back and RSVP</Center></Logo>
                    </LogoHolder>
                    </Link>
                </Nav>
            ) 
        } else {
            return (
                <Nav>
                    <Link to="/about">
                    <LogoHolder>
                        <Logo><Center>So, About this party?</Center></Logo>
                    </LogoHolder>
                    </Link>
                </Nav>
            )
        }
    }   
}

export {Wrapper}
export {Content}
export {BigSpace}
export {Spacer}
export {Descrip}
export {Center}
export {Bold}
export {GlobalTitle}
export {Navbar}
