import React from 'react';
import { Wrapper, Spacer, Descrip, Bold, Content, Center, BigSpace, Navbar, GlobalTitle } from './Elements';
import { connect } from "react-redux";
import styled from 'styled-components';
import request from 'superagent';
import { Link } from 'react-router-dom';
import { logout } from "../actions/index";
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout())
  };  
}
const mapStateToProps = state => {
  return { tokens: state.tokens };
};

const FormInput = styled.input`
    display:inline-block
    padding:8px;
    margin-left:5px;
    margin-bottom:5px;
    border:2px solid #cfcfcf;
    border-radius: 3px;
    width:25%;
    &:hover {
        border:2px solid #999999;
    }   
    &:focus {
        outline:none !important;
        background-color:#f5f8fc;
    }   
    @media (max-width: 414px) {
        width:90%;
    }   
`;

const SubmitInput = styled.input`
    margin-top: 5px;
    visibility: ${props => props.pano ? 'hidden' : 'visible'};
    border-radius: 4px;
    background-color:#d1432b;
    border:2px solid #d1432b;
    color:white;
    font-size:1em;
    padding:5px;
    text-align:center;
    cursor:pointer;
    margin-left: ${props => props.account ? '5px' : '0px;'};
    padding-top: ${props => props.account ? '3px' : '5px;'};
    &:hover {
        background: #990000;
        border:2px solid #990000;
    }
    &:focus {
            outline:none !important;
    }
`;

const AddButton = styled.button`
    background: white;
    color: #33672b;
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: 2px solid #33672b;
    border-radius: 3px;
    &:hover {
        background: #33672b;
        color: white;
    }
    &:focus {
        outline:none !important;
    } 
`;

const ShowLink = styled.a`
    color:#33672b;
    text-decoration:underline;
    &:hover {
        text-decoration:none;
        color:#003c00;
    } 
`;

class ConnectedAdmin extends React.Component {
    state = { 
        inputs: ['input-0'],
        amount:666,
        authenticated:false
    };
    logOut = this.logOut.bind(this);
    handleSubmit = this.handleSubmit.bind(this);
    appendInput = this.appendInput.bind(this);
    removeInput = this.removeInput.bind(this);
    miab = this.miab.bind(this);
    showInvite = this.showInvite.bind(this);
    componentWillMount() {
        // check for login tokens. if none exist, login.
        const cachedtokens = localStorage.getItem('auth_tokens');
        if (!cachedtokens) {
            // NO cached tokens check for store
            if (this.props.tokens.length === 0) {
                window.location.href = "/login";
            } else {
                if (this.props.tokens[0].success && this.props.tokens[0].success === "force password change") {
                    window.location.href = "/login";
                }
            }
        } else {
            var currentDate = new Date();
            var onehour = 60 * 60 * 1000;
            var ctn = currentDate.getTime();
            var ttn = new Date(localStorage.getItem("token_date"));
            ttn = ttn.getTime();
            if (ctn - ttn > onehour) {
                // tokens are expired!
                console.log("tokens exist in cache but are expired.");
                window.location.href = "/login";
            } else {
                this.setState({authenticated:true});
            }
        }
    }
    componentDidMount() {
        request
            .get('https://tmescfxqfb.execute-api.us-east-1.amazonaws.com/api/fullcircle')
            .set('X-API-Key', 'fuOxp3bk5q3iC9RKii03D9jFtgAV4q337LB7tMJX')
            .then(res => {
                // sanatize object
                let obj = JSON.parse(res.body.body);
                let iobj = obj.invites;
                let robj = obj.responses;
                let invites = {};
                let responses = {};
                for (var ikey in iobj) {
                    if (iobj.hasOwnProperty(ikey)) {
                        console.log("invite:", ikey, JSON.parse(iobj[ikey]));
                        invites[ikey] = JSON.parse(iobj[ikey]);
                    }
                }
                for (var rkey in robj) {
                    if (robj.hasOwnProperty(rkey)) {
                        responses[rkey] = JSON.parse(robj[rkey]);
                    }   
                }
                this.setState({invites:invites, responses:responses});
            });
    }
    componentDidUpdate() {
        if (this.state.inputs.length-1 === this.state.amount) {
            window.location.reload();
        }
    }
    logOut() {
        this.props.logout();
        window.location.reload();
    }
    handleSubmit(event) {
        event.preventDefault();
        var data = this;
        var emailname, namename, smsname, fname, fename, fsname, clean;
        var emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        for (var i = 0; i < data.state.inputs.length; i++) {
            emailname = "emailInput_input-"+i;
            namename = "nameInput_input-"+i;
            smsname = "smsInput_input-"+i;
            fname = "input-"+i;
            fename = "email_input-"+i;
            fsname = "sms_input-"+i;
            if (data[namename].value.length < 1) {
                alert("Please enter a name value");
                document.forms["invite"][fname].focus();
                break;
            }
            if (data[emailname].value.length < 1) {
                alert("Please enter an email value");
                document.forms["invite"][fename].focus();
                break;
            } else {
                if (!emailReg.test(data[emailname].value)) {
                    alert("Please enter a valid email.");
                    document.forms["invite"][fename].focus();
                    break;
                }
            }
            if (data[smsname].value.length < 1) {
                alert("Please enter an phone number value");
                document.forms["invite"][fsname].focus();
                break;
            } else {
                clean = data[smsname].value.replace(" ", "");
                clean = clean.replace("(", "");
                clean = clean.replace(")", "");
                clean = clean.replace("-", "");
                if (/[a-zA-Z]/.test(clean)) {
                    alert("Please enter a valid phone number.");
                    document.forms["invite"][fsname].focus();
                    break;
                }
            }
            this.miab(data[namename].value, data[emailname].value, i, clean);
        }
    }
    appendInput() {
        var newInput = `input-${this.state.inputs.length}`;
        this.setState(prevState => ({ inputs: prevState.inputs.concat([newInput]) }));
    }
    removeInput() {
        var array = [...this.state.inputs];
        array.splice(-1,1);
        this.setState({ inputs: array });
    }
    miab(name, email, number, phonenumber) {
        var data = this;
        var urlname = encodeURI(name);
        setTimeout(function(){ 
            request
                // {"key": "xxx", "invitename": "Andy%20Klier", "email": "pander@gmail.com", "phonenumber": "5124841678"}
                .post(process.env.REACT_APP_MASTER_OF_CEREMONY+'invite')
                .send({ key: "STW7NnsFp6OaqpYbWwI6ZnKTuJrnPr8GjAdb4jf0", invitename: urlname, email: email, phonenumber: phonenumber })
                .then(res => {
                    data.setState({amount:number}, function() {
                        console.log(data.state.amount, res.body, data.state.inputs.length);
                    });
                });
        }, 1000);
    }
    mapObject(object, callback) {
        return Object.keys(object).map(function (key) {
            return callback(key, object[key]);
        });
    }
    showInvite(token) {
        alert("http://shindig.shindagger.com/#"+token);
    }
    render() {
        var totalguests = 0;
        var stuff = this;
        if (this.state.authenticated) {
            return(
                <Wrapper color="white">
                    <Navbar />
                    <Content><BigSpace />
                    <Center>
                        <GlobalTitle title="Admin" large inline /><Link to="#" onClick={this.logOut} ><Descrip>[ Log Out ]</Descrip></Link><Spacer />

                        <form name="invite" onSubmit={this.handleSubmit}>
                            {this.state.inputs.map(goinput => 
                                <div key={goinput}>
                                    <FormInput 
                                        key={goinput}
                                        type="text"
                                        name={goinput}
                                        placeholder="name"
                                        ref={input => {
                                            this[`nameInput_${goinput}`] = input;
                                        }}
                                    /> 
                                    <FormInput 
                                        key={"email_"+goinput} 
                                        type="text"
                                        name={"email_"+goinput}
                                        placeholder="email"
                                        ref={input => {
                                            this[`emailInput_${goinput}`] = input;
                                        }}
                                    />
                                    <FormInput 
                                        key={"sms_"+goinput} 
                                        type="text"
                                        name={"sms_"+goinput}
                                        placeholder="phone number"
                                        ref={input => {
                                            this[`smsInput_${goinput}`] = input;
                                        }}  
                                    /> 
                                </div>
                            )}
                            <SubmitInput type="submit" value="Send Invites" />
                        </form>
                        <AddButton onClick={ () => this.appendInput() }>+</AddButton> <AddButton onClick={ () => this.removeInput() }>-</AddButton>
                            {this.state.invites && Object.keys(this.state.invites).length > 0 &&
                                <>
                            <Spacer />
                            <Bold>Invites</Bold>
                            <Spacer />
                                {this.mapObject(this.state.invites, function (key, value) {
                                    return (
                                        <div key={key}>
                                            {value.name} - {value.email} - <ShowLink href="#" onClick={(e) => {stuff.showInvite(value.id);}}>Show Invite Link</ShowLink> 
                                        </div>
                                    );
                                })}
                                </>
                            }
                            {this.state.responses && Object.keys(this.state.responses).length > 0 &&
                                <>
                            <Spacer />
                            <Bold>Responses</Bold>
                            <Spacer />
                                {this.mapObject(this.state.responses, function (key, value) {
                                    totalguests = totalguests + parseInt(value.guests);
                                    return (
                                        <div key={key}>
                                            {value.rsvpname} - {value.email} - {value.guests}
                                        </div>
                                    );
                                })}
                                </>
                            }
                            {totalguests > 0 &&
                                <div><Spacer /><Bold>Total Guests: </Bold> {totalguests}</div>
                            }
                    </Center>
                    </Content>
                </Wrapper>
            );
        } else {
            return(
                <Wrapper color="white">
                    <Navbar />
                    <Content><BigSpace />
                        <Spacer /><Center>No Auth</Center>
                    </Content>
                </Wrapper>
            );
        }
    }
}

const Admin = connect(mapStateToProps, mapDispatchToProps)(ConnectedAdmin);
export {Admin}
