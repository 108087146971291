import { GET_TOKENS, LOG_OUT } from "../constants/action-types";
import request from 'superagent';

export function login(payload) {
  return function(dispatch) {
    return request
      .post(process.env.REACT_APP_MASTER_OF_CEREMONY+'login')
      .send({ key: 'MWnyNLe51b8omMFJM5dpG9x9AzTFbNeo6YJLERFm', username: payload.userpass.username, password: payload.userpass.password })
      .then(res => {
        if (res.body.statusCode === 400) {
            const obj = JSON.parse(res.body.body);
            const error = "error, "+obj.error
            alert(error);
        } else {
            localStorage.setItem('token_date', (new Date()));
            localStorage.setItem('auth_tokens', JSON.parse(res.body.body));
            dispatch({ type: GET_TOKENS, payload: JSON.parse(res.body.body) });
        }
      }
    );
  };
}

export function logout() {
  return function(dispatch) {
    localStorage.removeItem('token_date');
    localStorage.removeItem('auth_tokens');
    dispatch({ type: LOG_OUT, payload:'' }); 
    return;
  };  
}
