import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Wrapper, Bold, Content, Center, Spacer, BigSpace, Navbar, GlobalTitle } from './Elements';
import us from '../img/us.jpg';

const maprotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(3deg);
  }
`;

const MapHolder = styled.div`
    border:solid 10px white;
    position:relative;
    display:inline-block;
    width:40vw;
    height:40vh;
    margin-top:20px;
    margin-bottom:20px;
    box-shadow: 0px 0px 12px 1px rgba(153,153,153,1);
    animation: ${maprotate} 3s;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    @media (max-width: 800px) {
        width:60vw;
    }
`;

const MyFrame = styled.iframe`
    width:100%;
    height:100%;
    border:0;
    margin: 0;
    padding: 0;
    overflow: hidden;
`;

const MapInfo = styled.div`
    display:inline-block;
    color:#666666;
    font-size:.9em;
    font-style:italic;
    margin-top:10px;
    margin-bottom:20px;
    width:40vw;
    @media (max-width: 800px) {
        width:60vw;
    } 
`;

const usrotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-1.75deg);
  }
`;

const Us = styled.img`
    width:30vw;
    height:auto;
    border:solid 10px white;
    box-shadow: 0px 0px 12px 1px rgba(153,153,153,1);
    animation: ${usrotate} 3s;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    @media (max-width: 800px) {
        width:50vw;
    }
    margin-bottom:20px;
`;

const Party = styled.div`
    display:inline-block;
    width:60vw;
    @media (max-width: 800px) {
        width:70vw;
    }
`;

const Itenerary = styled.div`
    text-align:left;
    margin-top:14px;
`;

const Item = styled.span`
    font-size:.9em;
`;

const SmallTitle = styled.div`
    display:inline;
    font-family: 'Amatic SC', cursive;
    font-size: 1.8em;
    color: ${props => props.green ? '#003c00' : '#990000'};
    @media (max-width: 600px) {
        font-size: 1.6em;
    }   
    @media (max-width: 330px) {
        font-size: 1.2em;
    } 
`;

const Lodging = styled.div`
    text-align:left;
    margin-top:14px;
`;

const Bye = styled.a`
    color:#33672b;
    text-decoration:underline;
    &:hover {
        text-decoration:none;
        color:#003c00;
    }
    @media (max-width: 600px) {
        font-size:.9em;
    }
`;

const Sidenote = styled.span`
    font-style:italic;
    font-size:.8em;
`;

class About extends React.Component {
    render() {
        return(
            <Wrapper color="white">
                <Navbar />
                <Content><BigSpace />
                <Center>
                    <GlobalTitle title="October 25-27, 2019" large />
                    <GlobalTitle title="Shindagger - 6701 Steeplerock Ridge Rd." green={true} />
                    <MapHolder>
                        <MyFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3387.1662144731254!2d-106.09678704936687!3d31.902080981152885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86e7371c0dec6387%3A0x141ab7ab5ba873a5!2s6701+Steeple+Rock+Ridge+Rd%2C+El+Paso%2C+TX+79938!5e0!3m2!1sen!2sus!4v1552918020394" frameBorder={0} allowFullScreen />
                    </MapHolder><br />
                    <MapInfo>
                        Using google or apple maps apps to get to our property can be tricky.  Our advice is to make sure your map app sends 
                        you down Montana Ave (62/180) all the way until you turn left on Hueco Tanks Rd (2775). Sometimes the maps app will send 
                        people down a once open, but now closed road, called Stagecoach Dr. <Bold>DO NOT</Bold> take that road. It will dead end 
                        at a fence bordering military&nbsp;land. 
                    </MapInfo>
                    <GlobalTitle title="You are cordially invited to join us" large />
                    <a href="https://imgur.com/a/0jvqfsm" target="_blank" rel="noopener noreferrer"><Us src={us} /></a><br />
                    <Party>
                        We did it! We tied the knot (<Bye href="https://imgur.com/a/0jvqfsm" target="_blank" rel="noopener noreferrer">photo album</Bye>), and we'd like you to join us in celebration at our land this coming fall, when the weather 
                        should be ideal and Hueco Tanks State Park (just a few miles away) isn't at max capacity every&nbsp;day.<Spacer />

                        No ceremony, or gifts, or expensive cakes (there WILL be pie!), just a small gathering of our closest friends and family, 
                        enjoying each other's company. Well behaved dogs are welcome to join if you are traveling by&nbsp;car!<Spacer />
                        
                        <Itenerary>
                            <GlobalTitle title="Itenerary" medium />

                            <SmallTitle green={true}>Before Arrival</SmallTitle> <Item>&mdash; A couple of reminders of what to bring:<br />
If you are flying and have a small camp chair, bring it! If you are driving and have a camp chair, bring it! We don't have any soft grass to sit on here.<br />
It'll likely be sunny, so bring sunscreen, sun hats, coverups, sunglasses etc.<br />
We will be providing disposable plates, utensils and cups, but if you want to be green, bring a personal cup, plate and utensils. We do have some extra cookware, but we live in vans, so...<br />
Bring a pair of sandals and bring a pair of comfortable hiking shoes... sneakers are fine. <br />
A small backpack, water bottle and granola bars would make your Sunday hike in Hueco Tanks State Park delightful!<br />
Because of our elevation, it can get chilly once the sun sets. Bring some pants and a warm sweater for evening festivities outside.</Item><br />
                            
                            <SmallTitle green={true}>Friday, October 25th</SmallTitle> <Item>&mdash; We recommend doing your food shopping before getting to our property. We are a thirty minutes drive to a grocery store called The Vista and a 40 minutes drive to an organic grocery called Sprouts.  There is also a huge liquor store called Specs in the same shopping center as Sprouts.  Sprouts and Specs are easily accessible from the airport.<br /> 

Once ya'll are settled in, we welcome all to a Magic Hour sunset on Shindagger, cocktails and maybe some karaoke! </Item><br />

                            <SmallTitle green={true}>Saturday, October 26th</SmallTitle> <Item>&mdash; Listen to records, Make breakfast and pies, smoke some meat, play some disc golf or horse shoes, go for a hike in the mountains, relax and enjoy&nbsp;yourself.</Item><br />

                            <SmallTitle green={true}>Sunday, October 27th</SmallTitle> <Item>&mdash; Rock Art tour of Hueco Tanks (for those able to join), and sadly, people start heading back&nbsp;home.</Item><br />
                        </Itenerary>
                        <Lodging>
                            <GlobalTitle title="Lodging" medium />
                            
                            There are a couple options for lodging in the Hueco Valley. Both places are no more than a 10 minutes drive from Shindagger. (Alternatively, there are many motels and hotels in town, the closest being about 30 minutes drive.)<Spacer /> 

                            <SmallTitle green={true}>Gleatherland</SmallTitle><br />
                            Our friends Heather and Glen run a campground on their property called Gleatherland. They have a series of trailers and yurts for rent. All trailers and yurts have water hook ups and power to charge all your things. There is a beautiful detached shower house for bathing but you will be pooping in a porta-potty. It's still pretty quiet here in October, but Gleatherland does book up fast, so we recommend booking ASAP.<br />
                            <Bye href="http://www.gleatherland.com/" target="_blank">Visit their website</Bye><Spacer />

                            <SmallTitle green={true}>The Hueco Hacienda</SmallTitle><br />
If you are looking for an actual house to stay in, the Hueco Hacienda is a beautiful Spanish Villa style house with private rooms as well as group rooms. Similar to a hostel, there is a shared bathroom with a flush toilet and shower, a large living room, full kitchen and WIFI. All you need is a suitcase!<br />
                            <Bye href="http://www.huecohacienda.com/" target="_blank">Visit their website</Bye> *<br />
                            <Sidenote>* You can view the rooms here, but booking must be done though email: <a href="mailto:info@huecohacienda.com">info@huecohacienda.com</a>. The Hacienda is currently changing managment, and we're not totally sure they're gonna be open next October. If interested, please email them directly and let us know what they say.</Sidenote><Spacer />
                            <SmallTitle green={true}>Camping With Us</SmallTitle><br />
                            We welcome anyone who wishes to tent-camp, or camp in their vehicles, to stay on Shindagger! We have a beautiful outdoor shower, as well as a bath-house with a sit-down shower in a clawfoot tub. Ya'll will be doing number 1 outside, and number 2 in our composting toilet. This entails sitting on a toilet seat that is attached to a 5 gallon paint bucket.<Spacer />

                            If you wish to camp, please bring your:<br />
                            <ul>
                                <li>Tent</li>
                                <li>Sleeping bags/pads</li>
                                <li>Any minimal cook set-up you have (cup, spoon/fork/knife, plate, bowl)</li>
                                <li>A french press or aero press for coffee, as well as a small pot for boiling water (we have a community trailer on the land with running water for washing dishes, as well as a solar set up where you can charge your phones/laptops)</li>
                                <li>Towel (we will have extra soap, shampoo etc.)</li>
                                <li>Headlamp</li>
                                <li>You can drink the water we have on the land, but it does taste a bit like pool water. We recommend bringing some jugs of water for drinking, bubbly water, etc.</li>
                            </ul>
                        </Lodging>
                    </Party>
                </Center>
                </Content>
            </Wrapper>
        );
    }
}

export {About}
